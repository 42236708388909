import image from '../images/mountains.jpg'
import img1 from '../images/Img22.png'
import img2 from '../images/vest_image.jpg'
const video = 'https://www.youtube.com/watch?v=eKS9M4bMh1w&list=RDeKS9M4bMh1w&start_radio=1'

export const TimelineData = [
  {
    image: image,
    date: '19. mars 1950',
    title: 'Herjólfur',
    text: 'Hinn fyrsti Herjólfur gekk daglega á milli Reykjavíkur og Vestmannaeyja, það sigldi auk þess hálfsmánaðarlega til Hornafjarðar og á sumrin hin síðari ár til Þorlákshafnar einu sinni í viku. Hann kom nýr til landsins í desember 1959 og var í þessum ferðum allt þar til í júlí 1974. Skipið var í eigu Skipaútgerðar ríkisins og höfðu Vestmannaeyingar lítið að segja um hvernig útgerð þess var háttað.'
  },
  {
    image: image,
    gallery: [
      {
        photo: img1,
        thumbnail: img1

      },
      {
        photo: img2,
        thumbnail: img2
      },
      {
        photo: img1,
        thumbnail: img1
      },
      {
        photo: img2,
        thumbnail: img2
      },
      {
        photo: img1,
        thumbnail: img1
      }
    ],
    date: '19. mars 1951',
    title: 'Herjólfur',
    text: 'Hinn fyrsti Herjólfur gekk daglega á milli Reykjavíkur og Vestmannaeyja, það sigldi auk þess hálfsmánaðarlega til Hornafjarðar og á sumrin hin síðari ár til Þorlákshafnar einu sinni í viku. Hann kom nýr til landsins í desember 1959 og var í þessum ferðum allt þar til í júlí 1974. Skipið var í eigu Skipaútgerðar ríkisins og höfðu Vestmannaeyingar lítið að segja um hvernig útgerð þess var háttað.'
  },
  {
    url: video,
    image: img2,
    date: '19. mars 1952',
    title: 'Herjólfur',
    text: 'Hinn fyrsti Herjólfur gekk daglega á milli Reykjavíkur og Vestmannaeyja, það sigldi auk þess hálfsmánaðarlega til Hornafjarðar og á sumrin hin síðari ár til Þorlákshafnar einu sinni í viku. Hann kom nýr til landsins í desember 1959 og var í þessum ferðum allt þar til í júlí 1974. Skipið var í eigu Skipaútgerðar ríkisins og höfðu Vestmannaeyingar lítið að segja um hvernig útgerð þess var háttað.'
  },
  {
    image: image,
    date: '19. mars 1953',
    title: 'Herjólfur',
    text: 'Í júnímánuði árið 1976 kom til Eyja ný Herjólfsferja í eigu hlutafélagsins Herjólfur hf. sem stofnað hafði verið um eign og rekstur flutningaskips milli lands og Eyja.'
  },
  {
    date: '19. mars 1955',
    title: 'Herjólfur',
    text: 'Í júnímánuði árið 1976 kom til Eyja ný Herjólfsferja í eigu hlutafélagsins Herjólfur hf. sem stofnað hafði verið um eign og rekstur flutningaskips milli lands og Eyja.Í júnímánuði árið 1976 kom til Eyja ný Herjólfsferja í eigu hlutafélagsins Herjólfur hf. sem stofnað hafði verið um eign og rekstur flutningaskips milli lands og Eyja.Í júnímánuði árið 1976 kom til Eyja ný Herjólfsferja í eigu hlutafélagsins Herjólfur hf. sem stofnað hafði verið um eign og rekstur flutningaskips milli lands og Eyja.'
  },
  {
    image: image,
    date: '19. mars 1954',
    title: 'Herjólfur',
    text: 'Hinn fyrsti Herjólfur gekk daglega á milli Reykjavíkur og Vestmannaeyja, það sigldi auk þess hálfsmánaðarlega til Hornafjarðar og á sumrin hin síðari ár til Þorlákshafnar einu sinni í viku. Hann kom nýr til landsins í desember 1959 og var í þessum ferðum allt þar til í júlí 1974. Skipið var í eigu Skipaútgerðar ríkisins og höfðu Vestmannaeyingar lítið að segja um hvernig útgerð þess var háttað.'
  },
  {
    image: image,
    date: '19. mars 1956',
    title: 'Herjólfur',
    text: 'Hinn fyrsti Herjólfur gekk daglega á milli Reykjavíkur og Vestmannaeyja, það sigldi auk þess hálfsmánaðarlega til Hornafjarðar og á sumrin hin síðari ár til Þorlákshafnar einu sinni í viku. Hann kom nýr til landsins í desember 1959 og var í þessum ferðum allt þar til í júlí 1974. Skipið var í eigu Skipaútgerðar ríkisins og höfðu Vestmannaeyingar lítið að segja um hvernig útgerð þess var háttað.'
  },
  {
    image: image,
    date: '19. mars 1956',
    title: 'Herjólfur',
    text: 'Hinn fyrsti Herjólfur gekk daglega á milli Reykjavíkur og Vestmannaeyja, það sigldi auk þess hálfsmánaðarlega til Hornafjarðar og á sumrin hin síðari ár til Þorlákshafnar einu sinni í viku. Hann kom nýr til landsins í desember 1959 og var í þessum ferðum allt þar til í júlí 1974. Skipið var í eigu Skipaútgerðar ríkisins og höfðu Vestmannaeyingar lítið að segja um hvernig útgerð þess var háttað.'
  }
]

export const faq = [
  {
    question: 'Hver er opnunartími afgreiðslu Herjólfs?',
    answers: [
      '<p>Afgreiðsla Herjólfs er opin alla daga frá kl. 06:30 til kl. 23:15 þegar siglt er frá Landeyjahöfn.</p>'
    ]
  },
  {
    question: 'Hver er opnunartími afgreiðslu Herjólfs?',
    answers: [
      '<p>Afgreiðsla Herjólfs er opin alla daga frá kl. 06:30 til kl. 23:15 þegar siglt er frá Landeyjahöfn.</p>',
      '<p>Afgreiðsla Herjólfs er opin alla daga frá kl. 06:30 til kl. 20:45 þegar siglt er frá Þorlákshöfn</p>'
    ]
  },
  {
    question: 'Hver er opnunartími afgreiðslu Herjólfs?',
    answers: [
      '<p>Afgreiðsla Herjólfs er opin alla daga frá kl. 06:30 til kl. 23:15 þegar siglt er frá Landeyjahöfn.</p>',
      '<p>Afgreiðsla Herjólfs er opin alla daga frá kl. 06:30 til kl. 20:45 þegar siglt er frá Þorlákshöfn</p>'
    ]
  }
]

import React from 'react'
import Main from '../../layouts/Main'
import { FAQ } from '../../components/'
import { faq } from '../../misc/mockData'

const Mockpage = () => {
  return (
    <Main>
      <FAQ data={faq} />
    </Main>
  )
}

export default Mockpage
